<template>
    <div class="terms-and-conditions">
        <div class="container">
            <div class="row">
                <div class="col-md-8 col-md-push-2">
                    <router-link :to="{path: '/'}">
                        <img src="@/assets/images/spotonlive-logo.svg" class="logo">
                    </router-link>

                    <a @click="goBack()" class="text-muted pull-right back" v-if="identity">Tilbage</a>

                    <div class="row">
                        <div class="col-md-12">
                            <card>

                                <h2>Handelsbetingelser og abonnementsbetingelser</h2>

                                <p>
                                    <br>
                                    Opdateret d.06/09-2017.
                                    <br>
                                    <br>

                                    Hjemmesiden <a href="http://spotonlive.dk">spotonlive.dk</a>,
                                    <a href="http://spoton.live">spoton.live</a>, og alle undersider ejes af:
                                    <br>
                                    <br>

                                    Generaxion A/S
                                    <br>
                                    Ryesgade 106A 4. sal,
                                    <br>
                                    2100 København Ø.
                                    <br>
                                    <br>
                                    CVR.: 34476489
                                </p>

                                <h3>Produktet</h3>
                                <p>Generaxion stiller adgang til online værktøjet OP'N til rådighed via.
                                    webapplikationen <a href="https://opnx.dk">https://opnx.dk</a>. Her vil
                                    der løbende blive tilført ny funktionalitet omhandlende marketing og online
                                    identitet til rådighed. De nærmere detaljer omkring ydelsen er angivet her:
                                    <a href="https://opnx.dk">https://opnx.dk</a>.</p>

                                <p>Adgangen til produktet foregår online – dvs. gennem hjemmesiden. Alt adgang til
                                    hjemmesiden foregår via. den bruger, som har tilknyttet sit betalingskort.</p>

                                <h3>Bestilling</h3>
                                <p>Præsentation af produkt sammen med prisen på <a href="https://opnx.dk">opnx.dk</a>
                                    er ikke et bindende tilbud, men blot en opfordring til at gøre tilbud.</p>

                                <h3>Garanti</h3>
                                <p>En bindende aftale mellem Generaxion A/S og kunden er således gældende fra
                                    betalings øjeblikket.</p>

                                <h3>Oprettelse og betaling</h3>
                                <p>Abonnementet på et medlemskab hos Generaxion A/S (via
                                    <a href="https://opnx.dk">https://opnx.dk</a>) tegnes som et løbende
                                    abonnement, der fortsætter, indtil abonnementet opsiges enten skriftligt eller
                                    online på <a href="https://app.opnx.dk/settings/profile/memberships">https://app.opnx.dk/settings/profile/memberships</a>
                                    når kunden er logget ind (se mere om dette under ’Opsigelse’).</p>
                                <p>Betaling for abonnement sker forud for hver abonnementsperiode. Det pågældende beløb
                                    trækkes automatisk via de, af kunden, angivne betalingsoplysninger. Sikkerheden
                                    omkring betalingsoplysningerne varetages af Stripe: <a href="https://stripe.com/dk">https://stripe.com/dk</a>
                                </p>
                                <p>Kvitteringer for betaling genereres straks efter betaling (både ved det initielle køb
                                    og ved fornyelse af abonnement) og opbevares på kundens profil og tilgås via
                                    <a href="https://app.opnx.dk/settings/profile/billing">https://app.opnx.dk/settings/profile/billing</a>
                                    når kunden er logget ind.</p>

                                <h3>Særligt om køb af medlemskab med prøveperiode</h3>
                                <p>Visse abonnementer/medlemskaber kommer med en gratis prøveperiode med i starten af
                                    medlemskabet. For at købe et medlemskab inklusiv denne gratis prøveperiode skal
                                    betalingsoplysninger indtastes som normalt, blot uden at der trækkes noget beløb fra
                                    kundens kort. Når prøveperioden slutter vil det oprindelige medlemskab aktiveres og
                                    der vil blive trukket penge på kundens kort. Såfremt kunden ikke ønsker at betale
                                    for abonnementet, skal kunden opsige medlemskabet inden prøveperioden udløber. Læs
                                    hvordan du opsiger dit abonnement senere på denne side.</p>

                                <h3>Brug</h3>
                                <p>Et medlemskab er individuelt og tilknyttet én virksomhed. Én bruger må ikke deles af
                                    en eller flere virksomheder. Overtrædelse vil medføre at abonnementet opsiges fra
                                    Generaxion A/S’ side.</p>

                                <h3>Ophavsret</h3>
                                <p>Alt indhold på OP'N og alle undersider tilhører Generaxion A/S. Al
                                    kopiering, distribution eller lignende brug af dette indhold er ikke tilladt. Du har
                                    som kunde ikke ret til kommercielt brug af vores indhold herunder blandt andet
                                    videresalg, distribution eller promovering af indholdet.</p>

                                <h3>Fortrydelse</h3>
                                <p>Hvis du fortryder dit køb kan du gøre det i op til 14 dage efter aftaleindgåelsen. Du
                                    kan kun bruge din fortrydelsesret, hvis du endnu ikke har taget dit abonnement i
                                    brug. I praksis betyder det, at du ikke er påbegyndt brug af ydelser, som
                                    forudsætter et Business-pakke abonnement.</p>
                                <p>Hvis du vil gøre brug af din fortrydelsesret, skal du - inden du tager abonnementet i
                                    brug og inden udløbet af 14-dagesfristen - i en utvetydig skriftlig erklæring
                                    meddele dette til Generaxion A/S, Ryesgade 106A 4. sal, 2100 København Ø.</p>

                                <h3>Garanti</h3>
                                <p>Generaxion A/S tilbyder dog en ekstraordinær tilfredshedsgaranti. Såfremt du
                                    ikke er tilfreds med kurserne kan du skrive til
                                    <a href="mailto:info@spotonmarketing.dk" rel="noreferrer noopener">info@spotonmarketing.dk</a>
                                    og få dine penge tilbage, såfremt du gør dette indenfor 14 dage efter
                                    aftaleindgåelsen. Såfremt du benytter dig af denne garanti, lukkes din adgang til
                                    OP'N. Denne tilfredshedsgaranti er kun gældende for køb på 1 licens,
                                    medmindre andet aftales.</p>

                                <h3>Opbevaring af data</h3>
                                <p>Hos Generaxion A/S opbevares data omkring kunden (men IKKE
                                    betalingsinformationer herunder kortnummer, udløbsmåned/år, sikkerhedskode). Disse
                                    data vil ikke blive videregivet til andre og vil kun blive brugt af SpotOn Marketing
                                    ApS.</p>

                                <h3>Forbehold</h3>
                                <p>Generaxion A/S tager forbehold for tryk- og prisfejl.</p>
                                <p>Generaxion A/S er ikke ansvarlig for nedbrud af eller svigtende adgang til
                                    hjemmesiden, nedbrud i strømforsyning eller internetforbindelse, hærværk på systemet
                                    (både fysisk såvel som computervirus og hacking), misbrug af personoplysninger eller
                                    andre forhold og omstændigheder, som er uden for Generaxion A/S kontrol.</p>

                                <h3>Cookies</h3>
                                <p>Generaxion A/S hjemmesider benytter cookies. Ved brug af hjemmesiderne
                                    accepteres dette. Brug af cookies har det formål at optimere websitet og dets
                                    funktionalitet, og dermed gøre besøget så nemt som muligt for dig.</p>
                                <p>Du kan til enhver tid slette cookies fra din computer, hvordan det sker vil afhænge
                                    af din browser.</p>

                                <h3>Opsigelse</h3>
                                <p>Abonnementet kan altid opsiges til udløbet af den indeværende abonnementsperiode.
                                    Opsigelsen af abonnement kan ske skriftligt til Generaxion A/S, Ryesgade 106A
                                    4. sal, 2100 København Ø eller via. hjemmesiden
                                    <a href="https://app.spotonlive.dk/settings/profile/memberships">https://app.spotonlive.dk/settings/profile/memberships</a>
                                    når kunden er logget ind.</p>

                                <h3>Ændring af kortoplysninger</h3>
                                <p>Såfremt kunden ønsker at ændre de, ved købet, angivne kortoplysninger kan dette gøres
                                    via denne side, inde på kundens profil:
                                    <a href="https://app.spotonlive.dk/settings/profile/billing">https://app.spotonlive.dk/settings/profile/billing</a>
                                </p>
                                <p>Kortoplysninger skal ikke sendes i en mail til SpotOn Marketing. Ændring af
                                    kortoplysninger kan kun foregå via hjemmesiden.</p>

                                <h3>Ændring af abonnementsbetingelserne</h3>
                                <p>Generaxion A/S har til enhver tid ret til at ændre nærværende
                                    abonnementsbetingelser, såfremt det sker med en varsling mindst 1 måned før
                                    ændringen finder sted. Denne varsling fremsendes til eksisterende kunder.</p>

                                <h3>Ansvarsfraskrivelse</h3>
                                <p>Generaxion A/S hjemmesider linker til andre hjemmesider i forbindelse med
                                    nyheder eller anden information. Generaxion A/S frasiger sig ansvar for det de
                                    andre sider laver, og kan således ikke drages til ansvar for lidte tab eller andre
                                    problematikker der er forårsaget eller fremprovokeret af disse sider.</p>
                                <p>Informationer der står på <a href="http://spotonlive.dk">spotonlive.dk</a>,
                                    <a href="http://app.spotonlive.dk">app.spotonlive.dk</a> og alle undersider
                                    repræsenterer Generaxion A/S’ subjektive holdning til et givent emne. Det er
                                    altså læserens eget ansvar at krydschecke informationen, såfremt det er kritisk at
                                    den skal være korrekt. Generaxion A/S er ikke erstatningsansvarlig over for
                                    direkte eller indirekte tab som følge af forkerte, misvisende eller manglende
                                    informationer eller forsinkelser ved levering af de af Generaxion A/S solgte
                                    produkter og ydelser. Generaxion A/S er ikke erstatningsansvarlig over for
                                    direkte eller indirekte tab som følge af de informationer der er tilgængelige på
                                    spotonlive.dk, app.spotonlive.dk og alle tilhørende undersider.</p>

                                <template v-if="identity">
                                    <hr>

                                    <a @click="goBack()" class="btn btn-primary pull-right btn-fill">Tilbage</a>

                                    <div class="clearfix"></div>
                                </template>
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .terms-and-conditions {
        p {
            font-size: 16px;
        }

        h3 {
            margin-top: 30px;
            margin-bottom: 15px;
        }

        .logo {
            width: 200px;
            margin: 50px 0 35px;
        }

        .back {
            margin-top: 65px;
        }
    }
</style>

<script>
  import Card from '@/app/shared/components/Card'

  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters('identity', {
        identity: 'getIdentity'
      })
    },

    methods: {
      goBack () {
        history.back()
      }
    },

    components: {
      Card
    }
  }
</script>
